.Home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x:hidden ;
}

.resto{
    height: 100vh;
}
