.hoteles {
  width: 100%;
  font-family: "Staatliches", sans-serif;
}
/* container */
.columnashoteles {
  display: block;

  width: 100%;
}
.titulohoteles .info__description {
  max-width: 650px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 65px;
}
.textohotel {
  font-size: 22px;
  line-height: 35px;
}
.muto {
  font-size: 14px;
  opacity: 0.7;
  line-height: 18px !important;
  display: block;
  margin-top: 20px;
}

/* columns */
.columnashoteles > * {
  padding: 2rem;
  text-align: center;
  min-height: 370px;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  gap: 20px;
}
.titulohoteles {
  text-align: center;
  padding-top: 60px;
  margin-bottom: 30px;
}
.columnashoteles,
.titulohoteles {
  color: #e4e4e4;
}
.hoteles .InfoButton__container {
  margin-top: 25px;
  padding-left: 0px;
}
.hoteles .btn {
  padding: 0.4rem 2rem;
}
/* tablet breakpoint */
@media (min-width: 768px) {
  .columnashoteles {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.hoteles .codigo {
  border: 1px dashed #c3ae46;
  padding: 5px 10px;
}
.blue_sea_puerto_de_la_cruz {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url("./img/blue_sea_puerto_de_la_cruz.jpg");
  background-position: center;
  background-size: cover;
}
.hotel_nh_tenerife {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url("./img/hotel_nh_tenerife.jpg");
  background-position: center;
  background-size: cover;
}
.hotel_urban_anaga {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url("./img/hotel_urban_anaga.jpg");
  background-position: center;
  background-size: cover;
}

figure {
  text-align: center;
}

figure span {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

figure span::before,
figure span::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 1px;
  top: calc(50% - 1px);
  background: #6c6c6c;
  opacity: 0.3;
}

figure span::before {
  right: 100%;
  margin-right: 25px;
}

figure span::after {
  left: 100%;
  margin-left: 25px;
}
figure img {
  max-width: 150px;
  filter: grayscale(100%);
}
@media screen and (max-width: 430px) {
  .columnashoteles > * {
    min-height: auto;
    padding: 2rem 1rem;
    gap: 10px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 932px) and (orientation: landscape) { 
    .columnashoteles > * {
        min-height: auto;
        padding: 2rem 1rem;
        gap: 10px;
      }

}