.btn {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-size: 22px;
  border-radius: 500px;
  overflow: hidden;
  background: hsl(50, 70%, 50%);
  color: ghostwhite;
  font-family: "Staatliches", sans-serif;
}

.btn span {
  position: relative;
  z-index: 1;
  transition: color 0.4s;
}

.btn:hover span {
  color: black;
}

.btn::before,
.btn::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.btn::before {
  content: "";
  background: #000;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.btn:hover::before {
  transform: translate3d(100%, 0, 0);
}

@media screen and (min-width: 820px) and (max-width: 1180px) {
    .btn{
        padding: 8px 20px;
    }
    .btn span{
        font-size: 18px;
    }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px){
  button{
    padding: 8px 20px;
  }
  button span{
    font-size: 18px;
  }
}

/*big smartphone*/
@media screen and (max-width: 430px){
  button{
    padding: 8px 20px;
  }
  button span{
    font-size: 18px;
  }
}