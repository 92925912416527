.stanga,
.dreapta {
  color: #fff;
  flex-basis: 50%;
}

.textoextra {
  opacity: 0.8;
  font-weight: 500;
}
.mijloc {
  text-align: center;
}
.mb-2 {
  margin-bottom: 2rem;
}
.timeline {
  width: 100%;
  height: 110vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Staatliches", sans-serif !important;
  background-color: #2b2b2b;
}
.MuiTypography-root {
  font-family: "Staatliches", sans-serif !important;
  font-size: 1.1rem !important;
}
.imgris {
  filter: brightness(0) saturate(100%) invert(42%) sepia(16%) saturate(23%)
    hue-rotate(314deg) brightness(100%) contrast(86%);
  max-width: 100%;
  height: auto;
}
.timeline__container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 30px;
  background-color: rgb(18 18 18);
  color: hsl(0, 0%, 75%);
}

.dreapta strong {
  color: hsl(50, 70%, 50%);
}

.stanga,
.dreapta {
  font-size: 20px;
  line-height: 1.2;
  padding-left: 20px;
  padding-right: 20px;
}

.timeline__titles {
  width: 100%;
}

.timeline__titles h2 {
  font-size: 45px;
  padding-left: 20px;
}

.timeline__titles span {
  font-size: 45px;
  color: hsl(50, 51%, 52%);
}

.nombrereto {
  color: hsl(50, 51%, 52%);
}

.timeline__content-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px 20px;
}

.timeline__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline__content p {
  font-size: 20px;
  line-height: 1.2;
}

.info__icon {
  padding-left: 15px;
  padding-bottom: 5px;
  height: 50px;
  filter: invert(100%);
}

.InfoButton__container {
  padding-left: 20px;
}

@media screen and (min-width: 820px) and (max-width: 820px) {
  .timeline {
    height: 50vh;
  }
  .timeline__container {
    width: 70%;
  }
  .timeline__titles h2 {
    font-size: 30px;
  }
  .timeline__titles span {
    font-size: 30px;
  }
  .info__description {
    font-size: 20px;
  }
  .info__icon {
    height: 30px;
  }
  .timeline__content p {
    font-size: 16px;
  }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .timeline {
    height: 55vh;
  }
  .timeline__container {
    width: 70%;
  }
  .timeline__titles h2 {
    font-size: 30px;
  }
  .timeline__titles span {
    font-size: 30px;
  }
  .info__description {
    font-size: 18px;
  }
  .info__icon {
    height: 30px;
  }
  .timeline__content p {
    font-size: 16px;
  }
}

/*big mobile*/
@media screen and (max-width: 430px) {
  .dreapta .MuiTabs-vertical {
    width: 45% !important;
  }
  .dreapta .MuiTabs-vertical button {
    font-size: 20px;
  }
  .dreapta .tabpanel {
    width: 55% !important;
  }
  .stanga,
  .dreapta {
    color: #fff;
    flex-basis: 100%;
  }
  .stanga .MuiTimeline-positionAlternate {
    padding: 0px;
  }
  .timeline {
    flex-wrap: wrap;
    height: auto;
    padding: 60px 0px;
  }
  .stanga {
    padding: 0px;
  }
  .dreapta {
    padding: 0 5px;
    margin-top: 60px;
  }
  .dreapta img {
    margin-top: 30px;
  }
  .timeline__container {
    width: 100%;
    padding: 0;
  }
  .timeline__titles span {
    font-size: 26px;
  }
  .timeline__titles h2 {
    font-size: 26px;
  }

  .InfoButton__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .info__description {
    width: 90%;
    font-size: 18px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 932px) and (orientation: landscape) {
  .speedFest__logo {
    width: 250px;
    height: 250px;
    top: calc(50% - 125px);
    left: calc(50% - 125px);

    z-index: 1;
  }
  .banner {
    height: 100vh;
  }
  .presentation__text {
    padding-right: 0px;
  }
  .presentation__title {
    font-size: 20px !important;
  }
  .presentation__slogan {
    display: none;
  }
  .presentation__text p {
    font-size: 16px;
  }
  .presentation__event {
    height: 90vh;
  }
  .timer__container,
  .buttons__container {
    width: 100%;
  }
  .buttons__container .btn {
    padding: 8px 20px;
    font-size: 15px;
  }
  .sponsors h2 {
    font-size: 26px;
  }
  .creators__container img {
    width: 25%;
    padding: 0px 5px;
    margin: 0px;
  }
  .sponsors__logos {
    height: auto;
  }
  .sponsors__brands-container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
    justify-content: center;
  }
  .timeline {
    height: unset;
    margin: 50px 0;
  }
  .information {
    height: 180vh;
  }
  .sponsors__brands-container {
    height: 140vh;
  }
  .dreapta .MuiTabs-vertical {
    width: 45% !important;
  }
  .dreapta .MuiTabs-vertical button {
    font-size: 20px;
  }
  .dreapta .tabpanel {
    width: 55% !important;
  }
  .stanga,
  .dreapta {
    color: #fff;
    flex-basis: 100%;
  }
  .stanga .MuiTimeline-positionAlternate {
    padding: 0px;
  }
  .footer__brand,
  .footer__menus {
    width: 50% !important;
    flex-direction: row;
  }
  .footer__brand img {
    max-width: 24%;
    margin: 0 !important;
  }
  .footer,
  .footer__brands-img,
  .footer__brand {
    flex-direction: row !important;
  }
  .telegram {
    margin: 15px 0px;
  }
  .retoss {
    height:480px!important;
  }
  .SpeedFest__Logo-footer {height: auto!important;}
}
