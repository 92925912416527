.footer{
    height:30vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: hsl(0, 0%, 91%);
    font-family: "Staatliches", sans-serif;
    background-color: #222222;
    padding: 60px 0px;
}

.footer__brand{
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.footer__brands-img{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer__slongan{
    text-align: center;
    width: 100%;
    font-size: 25px;
}

.SpeedFest__Logo-footer {
    max-width: 25%;
    margin:10px;
    padding:0 10px;
}

.Pirelli__Logo-footer{
    height: 100px;
}

.footer__menus{
    width: 40%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer__about, .footer__socials{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

}

.footer__about li {
font-size:18px;
list-style: none;
}

.footer__menus h3{
    font-size: 35px;
}

.footer__menus ul{
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.menus__links{
    font-size: 20px;
    color: #bfbfbf;
    transition: 0.2s ease;
    cursor: pointer;
}

.menus__links:hover{
    color: #ebebeb;
}
.telegram img {
    max-width: 30px;
    filter: brightness(0) invert(1);

}
.telegram {
    border: 1px solid #4c4c4c;
    padding: 5px;  
}
.telegram:hover {
    border: 1px solid hsl(50, 70%, 50%);
    padding: 5px;  
    color:hsl(50, 70%, 50%);
}
.telegram:hover img {
    filter: brightness(0) saturate(100%) invert(77%) sepia(100%) saturate(1262%) hue-rotate(344deg) brightness(96%) contrast(81%);;
}
.telegram span {
    vertical-align: super;
    margin-left:5px;
}

.subtelegram {
    color:#737373;
}

@media screen and (min-width: 820px) and (max-width: 1180px) {
    .footer{
        display: flex;
        flex-direction: column;
        padding: 64px 0px;
    }
    .Pirelli__Logo-footer{
        height: 80px;
    }
    .SpeedFest__Logo-footer{
        height: 150px;
    }
    .footer__menus{
        width: 100%;
    }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px){
    .footer{
        display: flex;
        flex-direction: column;
        padding: 64px 0px;
    }
    .Pirelli__Logo-footer{
        height: 80px;
    }
    .SpeedFest__Logo-footer{
        height: 150px;
    }
    .footer__menus{
        width: 90%;
        
    }
}

/*big mobile*/
@media screen and (max-width: 430px) {
    .telegram {
        margin-top:20px!important;        
    }
    .footer__about, .footer__socials 
    {height: 100%;}
    .footer__brand {width: 80%;}
    .footer{
        display: flex;
        flex-direction: column;
        padding: 64px 0px;
        height: 75vh;
    }
    .footer__brands-img {
        flex-direction: column;
        padding: 0px 20px;
    }
    .Pirelli__Logo-footer{
        height: 80px;
    }
    .footer__brands-img img {
        height: auto!important;
        max-width: 150px;
    }
    .SpeedFest__Logo-footer{
      max-width: 100%;
    }
    .footer__menus{
        width: 90%;
        text-align: center;
        margin-top: 45px;
    }
    .footer__menus li{
               text-align: center;
               margin:0 auto;
               line-height: 24px;
    }
    .footer__slongan{
        font-size: 20px;
    }
    .footer__menus h3{
        font-size: 25px;
        text-align:center;
        margin:0 auto;
    }
    .menus__links{
        font-size: 20px;
    }
    .subtelegram {
        font-size: 12px!important;
        display: block;
        width: fit-content;
        width: -moz-fit-content;
        margin:0 auto;
        max-width: 180px;
        line-height: 15px;
        margin-top: 10px;
        }
}