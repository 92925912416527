.presentation__event {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: url(./presentationIMG/back_presentacion.webp) center/cover
    no-repeat;
  height: 90vh;
  width: 100%;
  font-family: "Staatliches", sans-serif;
}

.presentation__slogan {
  font-size: 26px;
  color: #fff;
  line-height: 30px;
}

.presentation__title {
  font-size: 45px;
  color: #fff;
}
.resalta {
  color: hsl(50, 70%, 50%);
}

.presentation__year {
  color: hsl(50, 70%, 50%);
}

.presentation__imgs-container {
  width: 50%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.presentation__img {
  height: 90%;
}

.presentation__text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 40%;
  height: 90%;
  padding-right: 100px;
}

.presentation__text p {
  font-size: 22px;
  color: #fff;
}

.buttons__container {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}
.masinfo {
  margin-left: 15px;
}
.buttonmovil {
  display: none;
}

@media (min-height: 1200px) {
  .presentation__text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 20%;
    height: 90%;
    padding-right: 0px;
  }
}

@media screen and (min-width: 820px) and (max-width: 1180px) {
  .presentation__event {
    height: 30vh;
  }
  .presentation__slogan {
    font-size: 17px;
    width: 100%;
    width: 90%;
  }
  .presentation__title {
    font-size: 25px;
    width: 95%;
  }
  .presentation__year {
    font-size: 25px;
  }
  .presentation__text {
    padding: 0;
    width: 50%;
  }
  .presentation__text p {
    font-size: 15px;
    width: 90%;
  }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .presentation__event {
    height: 35vh;
  }
  .presentation__slogan {
    font-size: 17px;
    width: 100%;
    width: 90%;
  }
  .presentation__title {
    font-size: 25px;
    width: 95%;
  }
  .presentation__year {
    font-size: 25px;
  }
  .presentation__text {
    padding: 0;
    width: 50%;
  }
  .presentation__text p {
    font-size: 15px;
    width: 90%;
  }
}

/*big smartphone*/
@media screen and (max-width: 430px) {
  .btn:before {
    background-color: hsl(50, 70%, 50%);
  }
  .btn {
    color: rgb(22, 22, 22);
  }
  .buttonmovil {
    display: block;
    margin-bottom: 20px;
  }
  .masinfo {
    display: none;
  }
  .presentation__slogan {
    font-size: 26px;
    color: #fff;
    line-height: 28px;
  }
  .presentation__event {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .presentation__slogan {
    font-size: 17px;
    width: 100%;
  }
  .presentation__title {
    font-size: 25px;
    width: 100%;
  }
  .presentation__year {
    font-size: 25px;
  }
  .presentation__text {
    padding: 0;
    width: 96%;
    text-align: center;
  }
  .presentation__text p {
    font-size: 17px;
    line-height: 28px;
    width: 100%;
    text-align: center;
    align-content: center;
  }
  .presentation__imgs-container {
    display: none;
  }
  .buttons__container {
    width: 100%;
  }
  .presentation__event {
    display: block;
  }
  .columnizq,
  .imgvertical__container {
    width: 90% !important;
    padding: 0px 15px !important;
    display: block !important;
    height: fit-content !important;
  }
  .novedad2025 {
    height: fit-content !important;
    padding: 65px 0px !important;
  }
  .columnizq p,
  .columnizq li {
    font-size: 1.4rem !important;
    line-height: 2.2rem !important;
  }
  .presentation__title,
  .presentation__year {
    font-size: 32px;
    line-height: 41px;
  }
  .columnizq .presentation__slogan {
    font-size: 22px;
    line-height: 30px;
  }
  .imgvertical__container {
    margin-top: 35px;
  }
  .buttons__container {
    margin: 35px 0px;
  }
  .novedades {
    font-size: 32px !important;
    margin: 25px 0px 10px 0px;
  }
  .information__container {
    justify-content: flex-start !important;
  }
  .information {
    height: fit-content !important;
  }
  .information__container {
    padding: 35px 0px !important;
  }
  .information .information__titles {
    margin-top: 35px;
  }
}
.columnizq {
  width: 75%;
  padding-right: 120px;
  padding-left: 100px;
}
.columnizq li {
  color: #fff;
}
.columnizq p {
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
}
.columnizq .presentation__slogan {
  text-align: center;
  width: 100%;
  color: rgb(217, 187, 38);
  margin-bottom: 15px;
}
.columnizq li {
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
}
.novedad2025 {
  height: 150vh;
}
.imgvertical__container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  gap: 10px; /* opcional: espacio entre imágenes */
  margin-right: 100px;
}

.imgvertical__img {
  flex: 1;
  width: 100%;
  object-fit: cover;
  border-radius: 8px; /* opcional */
  opacity: 0.8;
}
