.header{
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    background-color: #222222da;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
    opacity: 0;
    transition: opacity 0.3s;
}

.header.visible{
    opacity: 1;
}

.logoHeader{
    height: 110px;
}

.menu{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu__list{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.menu__list-items{
    font-family: "Staatliches", sans-serif;
    font-size: 25px;
    color: #bfbfbf;
    transition: 0.2s ease;
}

.menu__list-items:hover{
    color: #ebebeb;
}

.navBar__mobile{
    display: none;
}

@media screen and (min-width: 820px) and (max-width: 1180px) {
    .logoHeader{
        height: 100px;
    }
    .menu__list-items{
        font-size: 20px;
    }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px){
    .logoHeader{
        height: 100px;
    }
    .menu__list-items{
        font-size: 20px;
    }
}

/*big smartphone*/
@media screen and (max-width: 430px){
    .header{
        display: none;
    }
    .logoHeader{
        height: 100px;
        margin-left: 50px;
    }
    .menu{
        display: none;
    }
    .navBar__mobile{
        display: flex;
        margin-right: 70px;
    }
}