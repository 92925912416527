.condiciones{
    padding:65px 35px;
    color:  hsl(0, 0%, 75%);
    font-family: "Staatliches", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    font-size: 18px;
    line-height: 22px;
}
.container {
max-width:1440px;
margin-left:auto;
margin-right:auto;

}
.condiciones h3, .condiciones h2, .condiciones h4 {
margin:20px 0px 10px 0px;

}

.container ol {
list-style: auto;
}
.container li {
   margin-bottom: 15px;
    }

.container p {
    margin-bottom:20px;
    }

.container strong {
    color: hsl(50, 70%, 50%);
    }

.titulo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content__404-container{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.titulo h1{
    font-size:40px;
    color: hsl(50, 70%, 50%);
    margin-bottom: 35px;
    line-height: 40px;
}

.titulo h2{
    font-size: 40px;
    color: hsl(50, 70%, 50%);
    margin-bottom: 35px;
}

.image404__container{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error__explanation{
    width: 100%;
    text-align: center;
}

/*big mobible*/

@media screen and (max-width: 430px) {
.titulo h1, .titulo h2 {
font-size: 35px;

}
  
}