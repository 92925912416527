.information{
    width: 100%;
    height: 110vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Staatliches", sans-serif;
    background: url(../../../public/img/info-bg.webp) center/cover no-repeat;
}

.information__container{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    color: hsl(0, 0%, 75%);
}

.info__description{
    font-size: 20px;
    line-height: 1.2;
    padding-left: 20px;
    padding-right: 20px;
}

.information__titles{
    width: 100%;
}

.information__titles h2{
    font-size: 45px; 
    padding-left: 20px;
}

.information__titles span{
    font-size: 45px; 
    color: hsl(50, 51%, 52%);
}

.information__content-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px 20px;
}

.information__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.information__content p{
    font-size: 20px;
    line-height: 1.2;
}

.info__icon{
    padding-left: 15px;
    padding-bottom: 5px;
    height: 50px;
    filter: invert(100%);
}

.InfoButton__container{
    padding-left: 20px;
}

@media screen and (min-width: 820px) and (max-width:820px) {
    .information{
        height: 50vh;
    }
    .information__container{
        width: 70%;
    }    
    .information__titles h2{
        font-size: 30px;
    }
    .information__titles span{
        font-size: 30px;
    }
    .info__description{
        font-size: 20px;
    }
    .info__icon{
        height: 30px;
    }
    .information__content p{
        font-size: 16px;
    }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px){
    .information{
        height: 55vh;
    }
    .information__container{
        width: 70%;
    }    
    .information__titles h2{
        font-size: 30px;
    }
    .information__titles span{
        font-size: 30px;
    }
    .info__description{
        font-size: 18px;
    }
    .info__icon{
        height: 30px;
    }
    .information__content p{
        font-size: 16px;
    }
}

/*big mobile*/
@media screen and (max-width: 430px) {
    .information{
        height: 190vh;
    }
    .information__container{
        width: 100%;
        padding: 0;
    }
    .information__titles span{
        font-size: 26px;
    }
    .information__titles h2{
        font-size: 26px;
    }
  
    .InfoButton__container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .info__description{
        width: 90%;
        font-size: 18px;
    }
}