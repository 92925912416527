.banner {
  width: 100%;
  height: 100vh; 
  display: flex;
  align-items: center;
  position: relative;
}

.banner div {
  height: 100%;
  width: 100%;
  position: relative;
}

.banner__img {
  width: 100%;
  height: 100%;
  position: relative;
  animation: darken 3s forwards;
  animation-delay: 1.5s;
}

@keyframes darken {
  to { filter: brightness(30%);}
}

.speedFest__logo{
  position: absolute;
  width: 650px;
  height: 650px;
  top:calc(50% - 350px);
  left:calc(50% - 350px);
  z-index: 1;
}

.presentation{
  position: absolute;
  top: 30%;
  left: 15%;
  z-index: 1;
  font-size: 90px;
  font-family: "Staatliches", sans-serif;
  color: hsl(50, 70%, 50%);
}

.pirelli{
  height: 120px;
  position: absolute;
  top: 28%;
  left: 56%;
  z-index: 1;
}

.perrosdeplata{
  position: absolute;
  top: 15%;
  left: 55%;
  z-index: 1;
  height: 350px;
}

.perrosdeplata__text{
  font-family: "Staatliches", sans-serif;
  position: absolute;
  font-size: 90px;
  top: 35%;
  left: 15%;
  z-index: 1;
  color: hsl(0, 0%, 75%);
}

/*Big tablet*/
@media screen and (min-width: 820px) and (max-width: 1180px) {
  .banner{
    height: 38vh;
  }
  .speedFest__logo{
    height: 200px;
    width: auto;
    top: 20%;
    left: 40%;
  }
  .slogan{
    font-size: 30px;
  }
  .slogan::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%; /* Ajusta según tu preferencia */
    width: 80%;
    height: 5px;
    background-color:  hsl(0, 0%, 75%);
  }
  .presentation{
    position: absolute;
    top: 35%;
    left: 15%;
    z-index: 1;
    font-size: 50px;
    font-family: "Staatliches", sans-serif;
    color: hsl(50, 70%, 50%);
  }
  .pirelli{
    height: 80px;
    top: 32%;
  }
  .perrosdeplata{
    height: 200px;
    left: 60%;
  }
  .perrosdeplata__text{
    font-size: 50px;
    left: 20%;
    top: 35%;
  }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .banner{
    height: 40vh;
    width: 100%;
  }
  .presentation{
    top: 30%;
    left: 15%;
    font-size: 50px;
  }
  .pirelli{
    height: 70px;
  }
  .slogan{
    font-size: 30px;
  }
  .slogan::after{
    height: 8px;
  }
  .perrosdeplata__text{
    font-size: 50px;
    top: 30%;
    left: 15%;
  }
  .perrosdeplata{
    height: 200px;
  }
  .speedFest__logo{
    height: 200px;
    width: auto;
    top: 20%;
    left: 40%;
  }
}

/*big smartphone*/
@media screen and (max-width: 430px) {
  .banner{
    height: 25vh;
    width: 100%;
  }
  .presentation{
    top: 35%;
    left: 10%;
    font-size: 25px;
  }
  .pirelli{
    height: 40px;
    top: 30%;
    left: 55%;
  }
  .slogan{
    font-size: 17px;
  }
  .slogan::after{
    height: 5px;
  }
  .perrosdeplata__text{
    font-size: 30px;
    top: 35%;
    left: 15%;
  }
  .perrosdeplata{
    left: 60%;
    top: 25%;
    height: 100px;
  }
  .speedFest__logo{
    height:110px;
    width: auto;
    top: calc(50% - 55px);
    left: calc(50% - 81px);
  }
}