.logo__headerMobile{
    height: 40px;
    padding:10px 0;
    filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(203deg) brightness(106%) contrast(103%);
}

.headerMobile{
    width: 100%;
    background-color: #222222;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.headerMobile{
    display: none;
}

.navBar__Mobile{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navBar__mobile-btn{
    border-radius: 0;
    border: none;
    width: 100%;
    background-color: #2b2b2b;
    cursor: pointer; 
    height: 100%;
}

.navBar__mobile-btn:active{
    background-color: #222222;
}

.menu__btn-img{
    height: 15px;
    filter: invert(90%);
    transition: transform 0.3s ease;
}

.rotate{
    transform: rotate(180deg);
}

.navBar__Mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
    padding: 10px 0px 0px 0px;
    background-color: #2b2b2b;
    font-family: "Staatliches", sans-serif;
    color: hsl(0, 0%, 75%);
    transition: max-height 0.5s ease; /* Cambio a animar la altura máxima */
    max-height: 0; /* Inicialmente oculto */
    overflow: hidden; /* Para ocultar el contenido que excede la altura */
  }
  
  .menuMobile__list-items {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin:6px 0px;
  }
  
  .menu__mobile.show {
    max-height: 200px; /* Altura máxima deseada, ajusta según sea necesario */
  }

@media screen and (max-width: 430px) {
    .headerMobile{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}