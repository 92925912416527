.sponsors{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: "Staatliches", sans-serif;
    padding: 60px 0px;
  
}

.sponsors h2{
    font-size: 60px;
    color: #919191;
    margin-bottom: 20px;
    margin-top: 40px;
}

.creators__container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
}

.creators__container img{
   max-width: 200px;
   margin:0 30px;
}

.logolp{
    width: 400px;
    margin:60px 0px;
    max-height: 100px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(203deg) brightness(106%) contrast(103%);
} 

.opacidad {
    opacity: .7;
}

.perrosdeplata-sponsors{
    height: 300px;
}

.op3 {
opacity: .3;
}


.op4 {
    opacity: .4;
    }
.op6 {
    opacity: .6;
}
.sponsors__brands-container{
    padding: 30px 0px;
    width: 80%;
    height: 1000px;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(5,1fr);
}   

.sponsors__logos{
    height: 80px;
   
}

.aspect-video, .videopromo {display: none;}

@media screen and (min-width: 820px) and (max-width: 1180px) {
    .sponsors h2{
        font-size: 40px;
    }
    .logoPirelli{
        height: 100px;
    }
    .perrosdeplata-sponsors{
        height: 200px;
    }
    .sponsors__brands-container {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 3 columnas de igual tamaño */
        grid-auto-rows: auto; /* Altura automática según el contenido */
        gap: 30px; /* Espacio entre elementos */
        justify-content: center; /* Centrar horizontalmente los elementos */
    }
    .sponsors__brands-container > * {
        width: 100%; /* Ocupar todo el ancho de la celda */
    }
    .sponsors__logos{
        height: 60px;
    }
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px){
    .sponsors__brands-container {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 3 columnas de igual tamaño */
        grid-auto-rows: auto; /* Altura automática según el contenido */
        gap: 20px; /* Espacio entre elementos */
        justify-content: center; /* Centrar horizontalmente los elementos */
    }
    .sponsors__brands-container > * {
        width: 100%; /* Ocupar todo el ancho de la celda */
    }
    .logoPirelli{
        height: 100px;
    }
    .perrosdeplata-sponsors{
        height: 200px;
    }
}

/*big smartphone*/
@media screen and (max-width: 430px){
    .creators__container {flex-direction: column;}
    .creators__container img {margin:20px 0px}
    .sponsors__logos {height: auto;}
    .logolp {max-width:60%}
    .sponsors__brands-container {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
        gap: 20px;
        justify-content: center;
    }
    .sponsors__brands-container > * {
        width: 100%;
    }
    .logoPirelli{
        height: 100px;
    }
    .perrosdeplata-sponsors{
        height: 200px;
    }
    .sponsors h2{
        font-size: 30px;
        padding:0 25px;
        text-align: center;
    }
    .aspect-video, .videopromo {
        display: block!important;
        aspect-ratio: 9 / 16;
        width: 100%
    }
    
}