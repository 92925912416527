.timer__container{
    height: 80px;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.days, .hours, .minutes, .seconds{
    background-color: rgba(0, 0, 0, 0.4);
    color:  hsl(0, 0%, 75%);
    height: 90%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 36px;
    border-radius: 6px;
}

.days span, .hours span, .minutes span, .seconds span{
    font-size: 18px;
}

/*Small tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px){
    .timer__container{
        height: 70px;
        width: 90%;
    }
    .days, .hours, .minutes, .seconds{
        font-size: 26px;
    }
    .days span, .hours span, .minutes span, .seconds span{
        font-size: 16px;
    }
}

/*big smartphone*/
@media screen and (max-width: 430px){
    .timer__container{
        width: 100%;
        height: 70px;
    }
    .days, .hours, .minutes, .seconds{
        font-size: 26px;
    }
    .days span, .hours span, .minutes span, .seconds span{
        font-size: 16px;
    }
}